import React, {useState, useEffect} from 'react';

function ThemeMode({ isDark, handleToggle }) {

    return (
        <> 
        <button className="" 
            // thememode={currentMode} 
            onClick={() => handleToggle()} 
            style={{position: 'absolute', zIndex: '100' }}>
            Click me! Current mode is
        </button>        
        </>
    )
}

export default ThemeMode 