import React, {useEffect, useState, Suspense} from 'react';
// import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Switch,
  useLocation,
  useParams
} from "react-router-dom";


// Components
import Loading from './Loading';
import Header from './Header';
import Content from './Content';
import Footer from './Footer';
// import ThemeData from './ThemesSettings';
import ThemeMode from './ThemeMode';
import ErrorBoundary from './ErrorBoundary';

import '../css/Main.css'

export function useReactPath() {
    const [path, setPath] = React.useState(window.location.pathname);
    const listenToPopstate = () => {
      setPath(window.location.pathname);
    };

    React.useEffect(() => {
      window.addEventListener("popstate", listenToPopstate);
      return () => {
        window.removeEventListener("popstate", listenToPopstate);
      };
    }, []);

    console.log('useReactPath', path);

    return path;

};

export function CurrentPageSet() {
    const location = useLocation();
    // return location.pathname;
    console.log(location);
}


function MainWrapperContainer() {

    // const $global = window.$; console.log($global);

    const theme_settings_default = {
        'layout': "dark",
        'header_style': "light",
        'menu_style': "dark",
        'menu_layout': "dark",
        'loading': {
            status: false,
            progress: 0
        },
        'footer': "fullscreen-footer",
        isNewPage: false,
        isDark: true,
        currentPage: 'about'
    }
    const [settings, setSettings] = useState(theme_settings_default);

    /* const site_content = {
        logos: {

        },
        projects_home: {

        },
        projects_list: {

        }, 
        about: {

        },
        press: {

        }
    } */
    
    // const [siteContent, setSiteContent] = useState(site_content);
    // const updateSiteContent = (content_type, content_new) => {
    //     const content = {...siteContent};
    //     content.content_type =  content_new;

    //     setSiteContent(content);
    // }

    const [isLoading, setIsLoading] = useState(true);
    // const [isWaiting, setIsWaiting] = useState(true);


    const [currentMode, setCurrentMode] = useState(false);


    
    // export const changeCurrentLoadingState = () => {
    // const [count, setCount] = useState(0);
    // return setCount(count + 1);
    // }

    const path = useReactPath();
    React.useEffect(() => {
      // do something when path changes ...
    }, [path]);

    // const changeCurrentLoadingState = () => {
    //     setIsLoading(!isLoading);
    // }

    // const changSettings = () => {
    //     const obj = {...settings};

    //     obj.isDark = !settings.isDark;

    //     setSettings(obj);
    // }

    return (
        <>
            <Router>
                <ErrorBoundary>
                <Loading /> 
                <Header />
                    {/* <ThemeMode 
                        isDark={settings.isDark}
                        handleToggle={() => changSettings() }
                    /> */}
                    {/* <Content mode={isDark ? 'dark' : 'light'} /> */}
                    <Suspense fallback={<Loading />}>
                        <Content 
                            theme_settings={settings} 
                            // handleContent={ () => updateSiteContent()} 
                            // loadingState={ () => changeCurrentLoadingState()}  
                        />
                    </Suspense>
                    <Footer type={theme_settings_default.footer}  />
                    {/* </ThemeData.Provider> */}
                </ErrorBoundary>
            </Router>
        </>
    )

    
}


export default MainWrapperContainer