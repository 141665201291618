import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

// import NavBar from './NavBar';

// import Home from './Home';
// import Projects from './Projects';
// import SingleProject from './SingleProject';
// import Logos from './Logos';
// import Random from './Random';
// import About from './About';
// // import NotFound from './NotFound';
// import Visualisations from './Visualizations';

const Home = React.lazy(() => import('./Home'));
const Projects = React.lazy(() => import('./Projects'));
const SingleProject = React.lazy(() => import('./SingleProject'));
const Logos = React.lazy(() => import('./Logos'));
const Random = React.lazy(() => import('./Random'));
const About = React.lazy(() => import('./About'));
const Visualisations = React.lazy(() => import('./Visualizations'));
const Press = React.lazy(() => import('./Press'));
const SinglePress = React.lazy(() => import('./SinglePress'));
const NotFound = React.lazy(() => import('./NotFound'));


function ContentTypes() {
  return (
    <Switch>
        <Route component={Home} path='/' exact />
        
        <Route component={Projects} path='/projects' exact />
        <Route component={SingleProject} path='/project/:slug' />
        <RedirectWithStatus status={301} from="/project" to="/projects" exact />

        <Route component={About} path='/about' />
        <Route component={Logos} path='/logos' />
        <Route component={Random} path='/random' />

        <Route component={Press} path='/press' exact />
        <Route component={SinglePress} path='/press/:slug' />

        <Route component={Visualisations} path='/visualisations' />
        
        <Route component={NotFound} path='*' />
    </Switch>
  );
}

function RedirectWithStatus({ from, to, status }) {
  return (
    <Route
      render={({ staticContext }) => {
        // there is no `staticContext` on the client, so
        // we need to guard against that here
        if (staticContext) staticContext.status = status;
        return <Redirect from={from} to={to} />;
      }}
    />
  );
}

export default ContentTypes;