import React from "react"
import { NavLink as Link } from "react-router-dom";
//import { withRouter } from "react-router";

function NavBar({setLoadingState}) {
    return (
        <div className="menu-wrapper">
            <ul className="menu">
                <li className="menu-item">
                    <Link to="/" exact>KapitanovDesign</Link>
                </li>
                <li className="menu-item"><Link activeClassName="active" to="/projects" onClick={setLoadingState}>Projects</Link></li>
                <li className="menu-item"><Link activeClassName="active" to="/about">About</Link></li>
                <li className="menu-item"><Link activeClassName="active" to="/logos">Logos</Link></li>
                <li className="menu-item"><Link activeClassName="active" to="/random">Random</Link></li>
            </ul>
        </div>

    )
}

export default NavBar
