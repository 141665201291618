import React, {useEffect} from 'react';
import { NavLink as Link } from "react-router-dom";
import $ from 'jquery'; 

import '../css/NavPrimary.css'

export default function NavPrimary() {
 
    useEffect(
        () => {

        $(window).on('load', function() {
            $('body').on('mouseenter', '.primary-nav > li', function () {
                $('.primary-nav > li').removeClass('menu-item-hover')
                $(this).addClass('menu-item-hover');
            }).on('mouseleave', '.primary-nav > li', function () {
            
                $('.primary-nav > li').removeClass('menu-item-hover')
                $('.primary-nav > li.menu-item-active').addClass('menu-item-hover');
            
            });
        });
    }, []);

    return (
        <nav className="primary-nav">
            <ul>
                <li><Link to="/projects">Projects</Link></li>
                <li><Link to="/visualisations">Visualisations</Link></li>
                <li><Link to="/logos">Logo Design</Link></li>
                <li><Link to="/press">Press</Link></li>
                <li><Link to="/about">About</Link></li>
            </ul>
        </nav>

    )
}