import React, { useRef, useEffect } from 'react';
import $ from 'jquery'; 
import { useReactPath } from "./Main"

import ContentTypes from './ContentTypes';

function Content({theme_settings, loadingCurrent, updateSiteContent}) {
    //  console.log('theme_settings', theme_settings);

    // const inputRef = useRef<HTMLInputElement>(null);
    // const content_el = this.content_el.current;
    const content_el = useRef();
    const settings = theme_settings;

    const path = useReactPath();
    React.useEffect(() => {
        console.log('path changed', path);
    }, [path]);

    useEffect(() => {
      
        var body = $('body'), 
            pageSettings = $(content_el).find('.page-settings'),
            dataLayout = pageSettings.data('layout');

        if (dataLayout != null) {
            var siteLayout = 'layout-' + dataLayout;

            body.addClass(siteLayout);
        }
    }, []);

    return (
        <div id="main" className="content"  ref={content_el}>
            <div className="page-settings" 
                data-layout={settings.isDark === true ? 'dark' : 'light'}
                data-header-style={settings.header_style}
                data-menu-style={settings.menu_style} 
                data-menu-layout={settings.menu_layout}>    
                </div>
            {/* <div className="page-settings" data-layout="dark" data-header-style="light" data-menu-layout="light"></div> */}
        
            {/* Това зарежда различните типове съдържание  */}
            <ContentTypes 
                updateSiteContent={updateSiteContent}
            />

        </div>
    )
}

export default Content


//     // static contextType = ButtonContext; 

//     componentDidMount() {

//     }

//     render() {
//         return (
//             <div id="main" className="content"  ref={this.content_el}>
//                 <div className="page-settings" 
//                     data-layout={this.props.theme_settings.isDark === true ? 'dark' : 'light'}
//                     data-header-style={this.props.theme_settings.header_style}
//                     data-menu-style={this.props.theme_settings.menu_style} 
//                     data-menu-layout={this.props.theme_settings.menu_layout}></div>
//                 {/* <div className="page-settings" data-layout="dark" data-header-style="light" data-menu-layout="light"></div> */}
               
//                 {/* Това зарежда различните типове съдържание  */}
//                 <ContentTypes />

//             </div>
//         )
//     }
// }

// export default Content