import React, {useEffect, useState} from 'react';
// import { withRouter } from "react-router";
// import { useHistory ,useLocation } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
function Footer(props) {

    const [location, setLocation] = useState('');
    const currnet_location = useLocation();

    let year = new Date().getFullYear();
    
    //console.log("location", location);
    // const history = useHistory()
    // history.location.pathname

    useEffect(() => {
        
        setLocation(currnet_location.pathname);
    },[currnet_location.pathname]);



    return (
        <>

      {(() => {
        if(location === "/") {
          return (<></>)
        } else if (location === "/about" || location === "/project") {
                  return (
                    <div className={props.type}>
                      {/* Fullscreen Footer Left Side */}
                      <div className="ff-left">
                          <a href="mailto:hello@pethemes.com">SAY HELLO!</a>
                      </div>
                      {/*/ Fullscreen Footer Left Side*/}

                      {/* Fullscreen Footer Right Side */}
                      <div className="ff-right">
                          <ul className="ff-socials">
                          <li><a href="#fb">Fb</a></li>
                          <li><a href="#tw">Tw</a></li>
                          <li><a href="#be">Be</a></li>
                          </ul>
                      </div>
                      {/*/ Fullscreen Footer Right Side */}
                    </div>
                  )
         } else {
          return (
            <footer className="site-footer">
              {/* Fullwidth Wrapper */}
              <div className="wrapper">
                {/* Column */}
                <div className="c-col-12">
                  <h1 className="thin" style={{margin: 0}}>Do you have a project or an idea?
                    <br /><a className="underline" href="{#}">hello@pethemes.com</a>
                  </h1>
                </div>
                {/*/ Column */}
              </div>
              {/* Fullwidth Wrapper */}
              {/* Fullwidth Wrapper */}
              <div style={{marginBottom: '75px'}} className="wrapper-full">
                <div className="c-col-6 hide-mobile" />
                {/* Column */}
                <div className="c-col-3 no-gap">
                  <div className="caption">VISIT</div>
                  <h5>32 Avenue of the Americas
                    <br />New York, NY 10013
                    <br />United States
                  </h5>
                </div>
                {/*/ Column */}
                {/* Column */}
                <div className="c-col-3 no-gap">
                  <div className="caption">FOLLOW</div>
                  {/* Footer List */}
                  <ul className="footer-list">
                    <li><a href="{#}" className="underline">Facebook</a></li>
                    <li><a href="{#}" className="underline">Twitter</a></li>
                    <li><a href="{#}" className="underline">Behance</a></li>
                    <li><a href="{#}" className="underline">Instagram</a></li>
                  </ul>
                  {/* Footer List */}
                </div>
                {/*/ Column */}
              </div>
              {/* Fullwidth Wrapper */}
              {/* Wrapper */}
              <div style={{marginBottom: 0}} className="wrapper">
                {/* Column */}
                <div className="c-col-6">
                  {/* Footer Menu */}
                  <ul className="footer-menu ">
                    <li><a href="{#}">Terms</a></li>
                    <li><a href="{#}">Privacy Policy</a></li>
                    <li><a href="{#}">Purchase</a></li>
                  </ul>
                  {/*/ Footer Menu */}
                </div>
                {/* Column */}
                {/* Column */}
                <div className="c-col-6 align-right">
                  <p className="copyright-text">Kapitanov Design &copy; {year}</p>
                </div>
                {/* Column */}
              </div>
              {/* Wrapper */}
            </footer>
          )
        }
      })()}
    </>
    )
}

export default Footer
