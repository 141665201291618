import React, { useEffect } from 'react';
import $ from 'jquery'; 
import anime from 'animejs/lib/anime.es.js';
import LocomotiveScroll from 'locomotive-scroll';

import { useReactPath, CurrentPageSet } from "./Main"

export default function Loading() {

    const path = useReactPath();
    React.useEffect(() => {
        console.log('loading changed', path);
    }, [path]);

    useEffect(
        () => {
        // const loader_el = this.loader_el.current;

        $(window).on('load', function () {

            var line = "";
            var overlay = "";
            var i = 1;
            var c = 1;
        
            while (i < 4) {
                line += '<span class="line line-' + i + '"></span>';
                i++;
            }
            $('.lines').prepend(line);

            while (c < 5) {
                overlay += '<span class="overlay overlay-' + c + '"></span>';
                c++;
            }
            $('.overlays').prepend(overlay);        
            
            var loadingAn = anime({
                targets: '.line',
                height: '100%',
                duration: 600,
                delay: 0,
                easing: 'easeInOutCubic',
                begin: function (anim) {
                    $('.cygni-loader').addClass('in');
                },
                complete: function (anim) {
        
                    $('.cygni-loader').addClass('out');
        
                    setTimeout(function () {
        
                        $('#main').addClass('loaded');
        
                    }, 100);
        
                    setTimeout(function () {
        
                        var peScroll = new LocomotiveScroll({
                            el: document.querySelector('#main'),
                            smooth: false,
        
                        });
        
                        window.peScroll = peScroll;
        
                    }, 250);

                        
                    setTimeout(function () {

                        $('.fullscreen-footer a').each(function (i, element) {
                            $(element).delay(i * 75).queue(function (next) {
                                $(this).addClass('span-in');
                                next();
                            });
                        });

                    }, 1250);
        
                    if ($('.portfolio-showcase').length < 1) {
                        setTimeout(function () {
        
                            if ($('.site-navigation').hasClass('classic')) {
                                $('.site-navigation.classic .menu > li > a > span').each(function (i, element) {
                                    $(element).delay(i * 75).queue(function (next) {
                                        $(this).addClass('span-in');
                                        next();
                                    });
                                });
        
                            } else {
                                $('.toggle-line').addClass('toggle-line-in');
                                
                            }
        
        
                        }, 1500)
        
                        setTimeout(function () {
                            $('.primary-nav li').addClass('menu-in');
                            $('.site-branding img').addClass('logo-in');
                            $('.primary-nav li').addClass('menu-in');
                        }, 1900);
        
                    } else {
                        setTimeout(function () {
                            $('.primary-nav li').addClass('menu-in');
                            $('.site-branding img').addClass('logo-in');
                            $('.primary-nav li').addClass('menu-in');

                            // console.log('primary', $('.primary-nav li'));
                        }, 1900)
                    }
        
        
                    setTimeout(function () {
        
                        $('.menu-item-active').addClass('menu-item-hover');
        
                    }, 1000);
        
        
        
                }
            })
        
            window.loadingAn = loadingAn;
        
            anime({
                targets: '.cygni-loader',
                bottom: '100%',
                duration: 600,
                delay: 0,
                easing: 'easeInOutCubic',
            });
        
            anime({
                targets: '.cygni-loader .counter',
                innerHTML: [0, 100],
                round: 1,
                duration: 600,
                delay: 0,
                easing: 'easeInOutCubic',
                update: function (anim) {
        
                    var number = $('.cygni-loader .counter').html();
        
                    if (number < 10) {
        
                        $('.cygni-loader .counter').prepend('00')
                    } else if ((number >= 10) && (number < 100)) {
                        $('.cygni-loader .counter').prepend('0')
                    }
        
                }
            });

            if ($('.has-animation').length > 0) {
                $('.has-animation').each(function () {
                    $(this).attr('data-scroll', 'true');
        
                    var haDelay = $(this).data('delay') + 's'
                    var haDuration = $(this).data('duration') + 's'
        
                    $(this).css({
                        transitionDelay: haDelay,
                        transitionDuration: haDuration
        
                    })
                });
            }

            // Page Settings //
            var body = $('body');
            var pageSettings = $('.page-settings');

            pageSettings.addClass('cako')

            var dataLayout = pageSettings.data('layout');

            if (dataLayout != null) {
                var siteLayout = 'layout-' + dataLayout;

                body.addClass(siteLayout)
            }

            var dataHeaderStyle = pageSettings.data('header-style');

            if (dataHeaderStyle != null) {
                //var headerStyle = 'header-style-' + dataLayout;

                $('.site-header').addClass(dataHeaderStyle)
            }

            var dataMenuStyle = pageSettings.data('menu-style');

            if (dataMenuStyle != null) {
                //var menuStyle = 'menu-style-' + dataMenuStyle;

                $('.site-navigation').addClass(dataMenuStyle)

            }

            // var dataMenuLayout = pageSettings.data('menu-layout');

            // if (dataMenuLayout != null) {
            //     var menuLayout = dataMenuLayout;

            //     $('.site-navigation').addClass(dataMenuLayout)

            // }

            var dataBackground = pageSettings.data('background');

            if (dataBackground != null) {
                var bodyBg = dataBackground

                body.css('background', dataBackground);
                $('.np-ov').css('background', dataBackground);


            } else if (dataLayout === "light") {
                body.css('background', '#ededed');

            } else if (dataLayout === "dark") {
                body.css('background', '#121212');

            }
            

        });
        
    }, []);

    return (
            <div className="load-wrapper">

                <div className="loading-text">Loading</div>

                <div className="lines"></div>

                {/* Page Loader */}
                <div className="cygni-loader">
                    <div className="counter" data-count="99">00</div>
                </div>
                {/* / Page Loader */}

                {/* Overlays (Don't Touch) */}
                <div className="overlays"></div>
                {/* /Overlays */}


            </div>
    );
}
