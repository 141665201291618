import React, { useEffect } from 'react';
import { NavLink as Link } from "react-router-dom";
import $ from 'jquery'; 
import 'jquery.marquee';

import NavBar from './NavBar'
import NavPrimary from './NavPrimary'

import '../css/Header.css'

function Header() {

   // const header = useRef();

    useEffect(() => {
        // const header_el = header.current;

        $('.menu > li > a').each(function () {
            var dataHover = $(this).text();
    
            var attrHref = $(this).attr('href');
    
            if (attrHref === '#') {
    
                $(this).addClass('no-trans')
            }
    
            if ($('.site-navigation').hasClass('classic')) {
    
                $(this).wrapInner('<span data-hover="' + dataHover + '"></span>')
    
            } else {
                $(this).attr('data-hover', dataHover);
            }
    
        });
    
        $('.menu > li').on('mouseenter', function () {
    
            $('.menu > li').removeClass('menu-item-hover')
            $(this).addClass('menu-item-hover');
    
        });
    
        $('.menu > li').on('mouseleave', function () {
    
            $('.menu > li').removeClass('menu-item-hover')
            $('.menu > li.menu-item-active').addClass('menu-item-hover');
    
        });
    
        var scA = $('.scrolling-button a').marquee({
            duplicated: true,
            delayBeforeStart: 0,
        });
    
        scA.marquee('pause');
    
        //// Menu Animations End
    
        /* Sub-Menus */
        $('.menu > .menu-item.has-children').on('click', function () {
            // var subClicks = $(this).data('clicks');
            $('.sub-menu').removeClass('sub-menu-in');
            var submenu = $(this).children('.sub-menu');
    
            submenu.addClass('sub-menu-in');
        });
        if (!$('.site-navigation').hasClass('classic')) {
    
            $('.sub-menu > .menu-item.has-children').on('mouseenter', function () {
    
                $('.sub-menu .sub-menu').removeClass('sub-sub-in')
    
                var subsubmenu = $(this).children('.sub-menu')
    
                subsubmenu.addClass('sub-sub-in')
    
    
            });
    
            $('.menu > .menu-item.has-children > .sub-menu').on('mouseleave', function () {
                $('.sub-menu .sub-menu').removeClass('sub-sub-in');
            });
    
    
        }
        /* Sub-Menus */
        $('.site-navigation').prepend('<span class="menu-ov menu-ov-1"></span><span class="menu-ov menu-ov-2"></span><span class="menu-ov menu-ov-3"></span><span class="menu-ov menu-ov-4"></span>')
    
        /* Menu Toggle Hamburger */
        $('.menu-toggle')
            .prepend('<span class="toggle-line toggle-line-1"></span><span class="toggle-line toggle-line-2"></span>')
            .on('click', function () {
    
            var clicks = $(this).data('clicks');
            if (clicks) {
    
                setTimeout(function () {
                    $('.site-header').removeClass('dark-nav-active light-nav-active');
                    $('.site-navigation').removeClass('nav-open');
                    $('.primary-nav li').addClass('menu-in');
                    
                }, 380);
    
                $('.sub-menu').removeClass('sub-menu-in');
                $('.sub-menu .sub-menu').removeClass('sub-sub-in')
    
                $('.menu-wrapper').css('visibility', 'hidden');
    
                scA.marquee('pause');
    
                $(this).removeClass('is-active');
    
                $('.menu-ov').removeClass('menu-ov-in');
    
                $('.menu > li > a').each(function (i, element) {
                    $(element).delay(i * 20).queue(function (next) {
                        $(this).removeClass('menu-item-comes');
                        next();
                    });
                });
    
                $('.mww-1, .mww-2').removeClass('mww-in');
    
                $('.widget-socials li').removeClass('so-li-in');
    
    
            } else { console.log("no clicks");
    
                $('.site-navigation').addClass('nav-open')
                $('.primary-nav li').removeClass('menu-in');
    
                $('.menu-wrapper').css('visibility', 'visible');
    
                $(this).addClass('is-active');
    
                $('.menu-ov').addClass('menu-ov-in');
    
                scA.marquee('resume');
    
    
                if ($('.site-navigation').hasClass('light')) {
                    setTimeout(function () {
                        $('.site-header').addClass('light-nav-active')
                    }, 150);
    
                } else {
    
                    setTimeout(function () {
    
                        $('.site-header').addClass('dark-nav-active')
                    }, 150);
    
    
                }
    
                setTimeout(function () {
    
                    $('.menu > li > a').each(function (i, element) {
                        $(element).delay(i * 30).queue(function (next) {
                            $(this).addClass('menu-item-comes');
                            next();
                        });
                    });
    
                }, 200);
    
                setTimeout(function () {
    
                    $('.mww-1, .mww-2').addClass('mww-in');
    
                    $('.widget-socials li').each(function (i, element) {
                        $(element).delay(i * 40).queue(function (next) {
                            $(this).addClass('so-li-in');
                            next();
                        });
                    });
    
                }, 300);
    
            }
            $(this).data("clicks", !clicks);
    
        });
        /* Menu Toggle Hamburger */

    
        $('.menu-item a').not('.no-trans').on('click', function () {
    
            setTimeout(function () {
                $('.sub-menu').removeClass('sub-menu-in');
                $('.sub-menu .sub-menu').removeClass('sub-sub-in')
    
            }, 5);
    
            $('.menu-wrapper').css('visibility', 'hidden');
    
            scA.marquee('pause');
    
            $('.menu > li > a').each(function (i, element) {
                $(element).delay(i * 20).queue(function (next) {
                    $(this).removeClass('menu-item-comes');
                    next();
                });
            });
    
            setTimeout(function () {
                $('.site-header').removeClass('dark-nav-active light-nav-active');
                $('.site-navigation').removeClass('nav-open')
            }, 300);
    
            $('.mww-1, .mww-2').removeClass('mww-in');
    
            $('.widget-socials li').removeClass('so-li-in');
    
            $('.menu-toggle').data('clicks', false).removeClass('is-active');

            $('.menu-ov').removeClass('menu-ov-in');

            $('.menu > li > a').each(function (i, element) {
                $(element).delay(i * 20).queue(function (next) {
                    $(this).removeClass('menu-item-comes');
                    next();
                });
            });
    
        })
    
        if ($(window).outerWidth() < 850) {
            $('.site-navigation').removeClass('classic')
        }
        
    
    });

    return (
        <header className="site-header">
    
            {/* <!-- Branding --> */}
            <div className="site-branding">
                <Link to="/" exact>
                    <img className="dark-logo" src="/assets/images/logo_kapitanov_dark.png" alt="Site Dark Logo" />
                    <img className="light-logo" src="/assets/images/logo_kapitanov_white.png" alt="Site Light Logo" />
                </Link>
            </div>
            {/* <!-- /Branding --> */}
    
            {/* <!-- Menu --> */}
            <div className="site-navigation">
    
                {/* <!-- Menu Toggle (Don't Touch) --> */}
                {/* <div className={"menu-toggle"}></div> */}
                <div className="menu-toggle"></div>
                {/* <!-- /Menu Toggle --> */}


                <NavPrimary />
    
                {/* <!-- Menu Overlays (Don't Touch) --> */}
                <div className="menu-overlays"></div>
                {/* <!-- Menu Overlays --> */}
    
                {/* Nav Bar Component */}
                <NavBar />

                {/* <!-- Menu Widget (Left) --> */}
                <div className="menu-widget-wrapper mww-1">
                    <div className="menu-widget">
                        <div className="menu-widget-title">
                            WE ARE ALWAYS HERE;
                        </div>
    
                        <h5>Burgas, Bulgaria</h5>
                        <h5>+359 898 788 218</h5>
                    </div>
                </div>
                {/* <!--/ Menu Widget (Left) --> */}
    
                {/* <!-- Menu Widget (Middle) --> */}
                <div className="menu-widget-wrapper mww-2">
                    <div className="scrolling-button">
                        <a href="{mailto:studio@kapitanovdesign.com}">studio@kapitanovdesign.com</a>
                    </div>
                </div>
                {/* <!--/ Menu Widget (Middle) --> */}
    
                {/* <!-- Menu Widget (Right --> */}
                <div className="menu-widget-wrapper mww-3">
                    <div className="menu-widget">
                        <ul className="widget-socials">
                            <li><a href="{https://www.facebook.com/Kapitanov-Design-110826822373930/}" target="_blank" rel="noreferrer">facebook.</a></li>
                            <li><a href="https://www.instagram.com/kapitanovdesign/" target="_blank" rel="noreferrer">instagram.</a></li>
                            {/* <li><a href="{#be}">behance.</a></li> */}
                            {/* <li><a href="{#tw}">twitter.</a></li> */}
                            {/* <li><a href="{#dr}">dribble.</a></li> */}
                           
                        </ul>
                    </div>
                </div>
                {/* <!--/ Menu Widget (Right) --> */}
    
            </div>
            {/* <!-- /Menu --> */}
        </header> 
    )
}

export default Header